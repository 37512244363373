<template>
  <div>
      <div class="timeline-items__right rounded-sm w-100 p-4" v-for="(company, index) in companiesInfo"
                            :key="'company-' + index" :class="mode ? 'card__day' : 'card__night'">
          <el-row :gutter="20">
          <el-col :span="5">
              <div class="company_logo">
                <img :src="
                        company.image
                          ? baseUrl +  company.image
                          : 'img/bekmega.jpg'
                      "
                      alt=""
                       style="max-height:350px; width:350px; object-fit:cover"
                    />
              </div>
          </el-col>
            <el-col :span="19">
              <h3>{{ company.name }}</h3>
              <ul class="tms-info-list">
                <li class="tms-info-item">
                  <b>{{ $t("message.name") }}</b> 
                  <span>{{company.name }}</span>
                </li>
                <li class="tms-info-item">
                  <b> {{ $t("message.director") }}</b> 
                    <span>{{ company.director }}</span>
                  </li>
                <li class="tms-info-item">
                  <b>{{ $t("message.country") }}</b> 
                  <span> {{ company.country  }}</span>
                </li>
                <li class="tms-info-item"><b>{{ $t("message.region") }}</b> <span>{{ company.city}}</span></li>
                <li class="tms-info-item"><b> {{ $t("message.address") }}</b> <span>{{company.address}}</span></li>
                <li class="tms-info-item"><b> {{ $t("message.web_site") }}</b> <span> <a :href="company.web_site" target="_blank">{{ company.web_site }}</a></span></li>
                <li class="tms-info-item"><b> {{ $t("message.email") }}</b> <span>{{company.email}}</span></li>
                <li class="tms-info-item"><b> {{ $t("message.phone_number") }}</b> <span>{{company.phone_number}}</span></li>
                <li class="tms-info-item"><b> {{ $t("message.description") }}</b> <span>{{company.description}}</span></li>
              </ul>
            </el-col>
          </el-row>
      </div>
        
  </div>
</template>

<script>
   import { mapGetters, mapActions } from "vuex";
   import show from "@/utils/mixins/show";
    export default {
        mixins: [show],
        
        data(){
            return {
                loadingData:false,
                baseUrl: process.env.VUE_APP_URL.substring(
            0,
            process.env.VUE_APP_URL.length - 3
          ),
            }
        },
        watch: {
           
        },
        mounted () {
            this. getCompaniesInfo();
        },
        computed: {
           ...mapGetters({
              companiesInfo: "company/companies_info",
              mode: 'MODE'
            }),
        },
        methods:{
            ...mapActions({
              getCompaniesInfo: "company/getCompaniesInfo",
          }),
            // fetchData() {
            //   console.log(this.$router.params);
            //   // if (!this.loadingData) {
            //   //   this.loadingData = true;
            //   //   this.show(this.selectedItem.id)
            //   //     .then((res) => {
            //   //       this.form = JSON.parse(JSON.stringify(res.data.result.data.company));
            //   //       console.log(this.form);
            //   //       if (this.form.image) {
            //   //         this.imageList.push({
            //   //           name: this.form.image,
            //   //           url: this.baseUrl + this.form.image,
            //   //         });
            //   //       }
            //   //       this.loadingData = false;
            //   //     })
            //   //     .catch((err) => {
            //   //       this.loadingData = false;
            //   //     });
            //   // }
            // },
            // lockUser(){
            //     // set lock password if it's not set
            //     if(this.lockPassword === ''){
            //         this.showSetLockPasswordModal = true;
            //     } else {
            //         this.TRIGGER_LOCK({ status: true });
            //     }
            // },
            // updateTimer () {
            //     this.$store.commit('setLockTimer', this.secTimer)
            //     this.is_can_reload = true
            //     if (this.is_can_reload) {
            //         location.reload();
            //     }
            // },
            // setLockPass(formName){
            //     this.$refs[formName].validate((valid) => {
            //     if (valid) {
            //         if (this.lockPassword) {
            //             if (bcrypt.compareSync(this.ruleForm.oldPassword, this.lockPassword)) {
            //                 this.UPDATE_PASSWORD({
            //                     password: bcrypt.hashSync(this.ruleForm.password, salt)
            //                 });
            //                 this.ruleForm.password = ''
            //                 this.ruleForm.oldPassword = ''
            //                 this.ruleForm.confirmPassword = ''
            //                 this.$notify({
            //                     title: 'Успешно',
            //                     message: 'Успешно',
            //                     type: 'success',
            //                 });
            //             } else {

            //             }
            //         } else {
            //             this.UPDATE_PASSWORD({
            //                 password: bcrypt.hashSync(this.ruleForm.password, salt)
            //             });
            //             this.ruleForm.password = ''
            //             this.ruleForm.oldPassword = ''
            //             this.ruleForm.confirmPassword = ''
            //             this.$notify({
            //                 title: 'Успешно',
            //                 message: 'Успешно',
            //                 type: 'success'
            //             });

            //         }
            //     } else {
            //         console.log('error submit!!');
            //         return false;
            //     }
            //     });

            // }
        }
    }
</script>
<style lang="scss" scope>
.tms-info-list{
  list-style: none;
  margin: 0;
  padding: 0;
  .tms-info-item{
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 30px;
    font-size: 14px;
    padding: 8px 0px;
    border-bottom: 1px solid #cacaca93;
    b{
      width: 20%;
    }
    span{
      width: 80%;
    }
    &:last-child{
    border-bottom: none;

    }
  }
}
    .el-select {
        width: 100% !important;
    }
    .border__notcorrect input {
        border-color: crimson !important;
    }
    .border__notcorrect input:hover {
        border-color: rgb(182, 0, 36) !important;
    }
    .label__error .el-form-item__error {
        padding-top: 12px !important;
    }
    .text__day {
    color: #fff;
}
.text__night {
    color: #000;
}
.mode__radionight .el-radio__inner {
    background: inherit !important;
    border: 1px solid inherit !important;
}
.mode__radionight .el-radio {
    color: #fff !important;
}
.mode__locknight .el-tabs__item {
    color: #fff;
}
.el-tabs__item.is-active {
    color: #409EFF !important;
}
.label__night label {
    color: #fff !important;
}
.card__night{
    color: #fff !important;
    .tms-info-item{
      a{
        color: #fff !important;
      }
    }
}
.checkbox__night .el-checkbox__inner {
    background: inherit;
}
</style>
